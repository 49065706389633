<template>
  <div>
    <van-form ref="form">
      <!-- 审核信息 -->
      <van-cell-group title="审核信息">
        <stu-card-audit ref="audit" :app-params="{ coreKey: $route.query.id,  mkCode: 'XSZ',coreCode:'XSZBB' , status:$route.query.status,level:4}" />
      </van-cell-group>
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.stuName" readonly label="姓名" />
        <van-field v-model="form.stuNo" readonly label="学号" />
        <van-field v-model="form.gradeNo" readonly label="年级" />
        <van-field v-model="form.unitName" readonly label="院系" />
        <van-field v-model="form.specName" readonly label="专业" />
        <van-field v-model="form.className" readonly label="班级" />
        <van-field v-model="form.cardNo" readonly label="证件号码" />
        <van-field v-model="form.description" rows="3" class="textarea-style" arrow-direction="" readonly label="申请说明" type="textarea" maxlength="300" />
      </van-cell-group>
      <!-- 其他信息 -->
      <van-cell-group title="证明附件" class="upload-file" style="margin-bottom:65px;">
        <van-uploader v-model="fileList" :max-count="1" />
      </van-cell-group>
    </van-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import crudStuCardReplacement from '@/api/modules/daily/stuCardReplacement'
import stuCardAudit from "@/view/modules/common/stuCardAudit";


export default {
  name: "StuPayReport",
  components: { stuCardAudit },
  data() {
    return {
      form: {
        id: null,
        description: null,
        file: null,
        filename: null,
        unitName: null,
        specName: null,
        gradeNo: null,
        className: null,
        cardNo: null,
      },
      loading: false,
      fileList: []
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    this.$nextTick((e) => {
      crudStuCardReplacement.getById(this.$route.query.id).then((res) => {
        this.form = { ...res }
        let img = crudStuCardReplacement.dbImgView(this.$route.query.id)
        this.fileList = [{
          url: img, isImage: true, deletable: false
        }]
      })
    })
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
::v-deep.radio-other-info {
  .van-cell {
    .van-field__label {
      width: 11.2em;
    }
  }
}

.van-field__label {
  width: 110px;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
.textarea-style {
  height: 100%;
}
</style>