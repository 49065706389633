import request from '@/utils/request'
import api from '@/config/settings'



export function getById(id) {
  return request({
    url: 'api/stuCard/replacement/byId/' + id,
    method: 'get',
  })
}



export function add(form) {

  let data = new FormData();
  data.append("file", form.file);
  data.append("filename", form.filename);
  data.append("description", form.description);

  return request({
    url: 'api/stuCard/replacement',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/stuCard/replacement',
    method: 'delete',
    data: ids
  })
}

export function edit(form) {
  let data = new FormData();
  if (form.file !== 1) {
    data.append("file", form.file);
  }
  data.append("filename", form.filename);
  data.append("description", form.description);
  data.append("id", form.id);

  return request({
    url: 'api/stuCard/replacement/edit',
    method: 'post',
    data
  })
}

export function dbImgView(id) {
  return api.BaseURL + "/api/stuCard/replacement/viewImg/" + id;
}

export function downloadPdf(id) {
  return request({
    url: '/api/stuCard/replacement/pdf/' + id,
    method: 'get',
    responseType: 'blob'
  })
}


export default { add, edit, del, dbImgView, downloadPdf, getById }
